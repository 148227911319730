import React from 'react'
import { LPResourceSection, ResourceDocObject } from '../../api/Types'
import LandingPageSectionWrapper from '../common/LandingPageSectionWrapper'
import DynamicCallToAction from '../common/DynamicCallToAction'
import { TechDocsUrlPrefixes, TechDocsTypeNameEscaped } from '../../models/Constants'
import { useRequestContext } from '../../../common/providers/RequestProvider'

type Props = LPResourceSection

const isTutorialVideo = doc => doc.class_model_name === 'TutorialVideo'

const getDocumentUrl = (doc: ResourceDocObject) => {
  let prefixUrl: string | null = ''
  if (doc?.class_model_name === 'TechnicalDocument') {
    prefixUrl =
      (doc.document_type_name && TechDocsUrlPrefixes.TechnicalDocument[doc.document_type_name]) ||
      null
  } else if (isTutorialVideo(doc)) {
    return `/resources/video-tutorials/${doc.url_slug}`
  } else if (doc?.class_model_name) {
    prefixUrl = TechDocsUrlPrefixes[doc.class_model_name]
  }

  if (prefixUrl && doc.url_slug) {
    return `/resources/${prefixUrl}/${doc.url_slug}`
  }
  return null
}

const shouldDisplayResourceImg = doc => doc.title_image || (isTutorialVideo(doc) && doc.youtube_id)

const getDocumentCategory = (doc: ResourceDocObject) => {
  let cat: string | null = ''
  if (doc?.class_model_name === 'TechnicalDocument') {
    cat =
      (doc.document_type_name &&
        TechDocsTypeNameEscaped.TechnicalDocument[doc.document_type_name]) ||
      null
  } else if (doc?.class_model_name) {
    cat = TechDocsTypeNameEscaped[doc.class_model_name]
  }
  return cat
}

const makeDocListItem = (
  uuids: ReadonlyArray<string>,
  resourcesByUuid: Record<string, ResourceDocObject>
) => {
  const { isBot } = useRequestContext()

  return (
    <ul className='resource-doc-list'>
      {uuids.map((uuid, i) => {
        const doc = resourcesByUuid[uuid]

        if (!doc) {
          console.warn('No resource found for uuid', uuid)
          return null
        }

        const documentUrl = getDocumentUrl(doc)
        const isDocTutorialVideo = isTutorialVideo(doc)

        if (!documentUrl) {
          console.warn('No url slug was provided for tech doc', doc)
          return null
        }

        const documentCat = getDocumentCategory(doc)

        return (
          <li key={`resource-tech-doc-${i + 1}`}>
            <a className='docs-tech-doc-list-item' href={documentUrl}>
              {shouldDisplayResourceImg(doc) && (
                <div className='docs-tech-doc-list-item-image-wrapper'>
                  <div className='docs-tech-doc-list-item-image'>
                    {!isBot && (
                      <img
                        src={
                          isDocTutorialVideo
                            ? `https://img.youtube.com/vi/${doc.youtube_id}/maxresdefault.jpg`
                            : doc.thumbnail_title_image
                        }
                        alt={doc.title}
                        title={doc.title}
                        loading='lazy'
                      />
                    )}
                  </div>
                </div>
              )}
              <div className='docs-tech-doc-list-item-info'>
                {documentCat && (
                  <div className='docs-tech-doc-list-item-description'>{documentCat}</div>
                )}
                {doc.title && <h3 className='docs-tech-doc-list-item-title'>{doc.title}</h3>}
              </div>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

const LandingPageResource: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  sectionCTA,
  uuids,
  style,
  resourcesByUuid,
}) => {
  return (
    <LandingPageSectionWrapper cssNamespace='landing-page-resource-section' style={style}>
      {title && <h2 className='resource-section-title'>{title}</h2>}
      {subtitle && (
        <div
          className='resource-section-subtitle'
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      )}
      {uuids && makeDocListItem(uuids, resourcesByUuid)}
      {sectionCTA && <DynamicCallToAction cta={sectionCTA} />}
    </LandingPageSectionWrapper>
  )
}

export default LandingPageResource
