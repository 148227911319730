import React, { useState } from 'react'
import Search from '../../common/Search'
import ClickDropdown from '../../common/ClickDropdown'

interface Props {
  readonly clickDropdowns: ReadonlyArray<ClickDropdownInner>
  readonly searchPlaceholder?: string
  readonly searchTerm?: string
  readonly updateQuery?: (queryParams: Record<string, any>) => void
  readonly initialQuery?: Record<string, any>
  readonly orderBy?: string
  readonly searchFunction?: (newValue: string) => void
  readonly searchIsLoading?: boolean
}

interface ClickDropdownInner {
  readonly label: React.ReactNode
  readonly inner: React.ReactNode
  readonly overflowHidden?: boolean
}

const ResourceHeaderMobile: React.FunctionComponent<Props> = ({
  clickDropdowns,
  searchPlaceholder = '',
  searchTerm,
  updateQuery,
  initialQuery,
  orderBy,
  searchFunction,
  searchIsLoading,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [searchActive, setSearchActive] = useState(false)

  const classNames = ['mobile-resource-header']

  if (expanded) {
    classNames.push('mobile-resource-header-expanded')
  }

  if (searchActive) {
    classNames.push('mobile-resource-header-search-active')
  }

  function getSearchResults(
    searchTerm: string,
    updateQuery?: (query: Record<string, any>) => void
  ) {
    if (!updateQuery) return
    searchTerm === '' && initialQuery
      ? updateQuery({ ...initialQuery, search: undefined })
      : updateQuery({ search: searchTerm, order_by: orderBy })
  }

  function displayClickDropdown(clickDropdown, idx): React.ReactNode {
    return (
      <ClickDropdown
        key={idx}
        onExpand={onExpand}
        onHide={onHide}
        label={clickDropdown.label}
        overflowHidden={clickDropdown.overflowHidden ?? true}
      >
        {clickDropdown.inner}
      </ClickDropdown>
    )
  }

  function onExpand() {
    setExpanded(expanded => !expanded)
  }

  function onHide() {
    setExpanded(false)
  }

  function onSearchClick() {
    setSearchActive(searchActive => !searchActive)
  }

  return (
    <div className={classNames.join(' ')}>
      <label
        htmlFor='search-section'
        className='mobile-resource-header-search-label'
        onClick={onSearchClick}
      >
        <span>Expand search</span>
      </label>
      <button onClick={onSearchClick} className='mobile-resource-header-search-close'>
        Cancel
      </button>
      {clickDropdowns && clickDropdowns.map(displayClickDropdown)}
      <Search
        key={searchTerm}
        inputId='search-section'
        autoSearch={true}
        autoFocus={true}
        searchTerm={searchTerm}
        searchFunction={newValue =>
          searchFunction ? searchFunction(newValue) : getSearchResults(newValue, updateQuery)
        }
        placeholder={searchPlaceholder}
        clearSearch={true}
        isLoading={searchIsLoading}
      />
    </div>
  )
}

export default ResourceHeaderMobile
