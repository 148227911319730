import React from 'react'
import DynamicImageComponent from '../../../common/DynamicImage'
import { LPInfoGraphicSection } from '../../../../components/cms/api/Types'
import DynamicCallToAction from '../common/DynamicCallToAction'
import LandingPageSectionWrapper from '../common/LandingPageSectionWrapper'
import { useRequestContext } from '../../../common/providers/RequestProvider'

type Props = LPInfoGraphicSection

const LandingPageInfoGraphic: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  imageUrl,
  imageUrlMobile,
  description,
  cta,
  alt,
  imageMaxWidth,
  style,
}) => {
  const { isBot } = useRequestContext()

  const imageStyles = {
    maxWidth: imageMaxWidth,
    ...(textAlign === 'center' && { marginLeft: 'auto', marginRight: 'auto' }),
    ...(textAlign === 'right' && { marginLeft: 'auto' }),
  }

  return (
    <LandingPageSectionWrapper cssNamespace='info-graphic-section' style={style}>
      {(title || subtitle || imageUrl || imageUrlMobile) && (
        <div className='info-graphic-section-header' style={{ textAlign }}>
          {title && <h2 className='info-graphic-section-title'>{title}</h2>}
          {subtitle && <div className='info-graphic-section-subtitle'>{subtitle}</div>}
          {!isBot && (imageUrl || imageUrlMobile) && (
            <DynamicImageComponent
              style={imageStyles}
              imgLarge={imageUrl}
              imgMedium={imageUrlMobile}
              imgDefault={imageUrl || imageUrlMobile}
              alt={alt || title}
            />
          )}
        </div>
      )}
      {description && (
        <div
          className='info-graphic-description show-mobile-only'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {cta && <DynamicCallToAction cta={cta} />}
    </LandingPageSectionWrapper>
  )
}

export default LandingPageInfoGraphic
