import React, { Component, useState } from 'react'
import Modal from 'react-modal'

interface Props {
  readonly modalIsOpen?: boolean
  readonly closeModal?: () => void
  readonly url: string
}

// NOTE: This component must be wrapped in a ModalOpen component. It cannot function on it's own.
const VideoModal: React.FunctionComponent<Props> = ({ modalIsOpen, closeModal, url }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel='Example Modal'
      className='react-modal video-modal'
      overlayClassName='react-modal-overlay'
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
    >
      <div className='embed-container'>
        <iframe src={url} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen />
      </div>
      <button type='button' onClick={closeModal} className='video-modal-close' aria-label='Close'>
        &times;
      </button>
    </Modal>
  )
}

export default VideoModal
