import React from 'react'
import { LPLRContentSection, LRContentObject, CallToAction } from '../../api/Types'
import DynamicCallToAction from '../common/DynamicCallToAction'
import ModalOpen from '../../../common/modals/ModalOpen'
import VideoModal from '../../../common/modals/VideoModal'
import LandingPageSectionWrapper from '../common/LandingPageSectionWrapper'
import { useRequestContext } from '../../../common/providers/RequestProvider'

type Props = LPLRContentSection

const buildMediaComponent = (
  imageUrl,
  videoUrl,
  videoModalUrl,
  alt,
  imageBackgroundColor,
  videoRatio
) => {
  const { isBot } = useRequestContext()

  if (videoUrl) {
    return (
      <div className='embed-container' style={{ paddingBottom: `${100 / videoRatio}%` }}>
        <iframe className='allow-scrolling' src={`${videoUrl}?background=1`} frameBorder='0' />
      </div>
    )
  } else if (!videoModalUrl) {
    return (
      <div
        className={`lr-content-media-wrapper ${
          imageBackgroundColor ? `bg-color-${imageBackgroundColor}` : ''
        }`}
      >
        {!isBot && <img src={imageUrl} alt={alt} loading='lazy' />}
      </div>
    )
  } else {
    return (
      <ModalOpen
        buttonComponent={
          <div className='lr-content-video-play' style={{ backgroundImage: `url(${imageUrl})` }}>
            <i className='fa fa-play-circle' />
          </div>
        }
        modalComponent={<VideoModal url={videoModalUrl} />}
      />
    )
  }
}

const buildContentComponent = (
  pageTypeFlag?: string,
  title?: string,
  content?: ReadonlyArray<LRContentObject>,
  cta?: CallToAction,
  titleSize?: string
) => (
  <div className='lr-content-text-section'>
    {pageTypeFlag && <div className='lr-content-page-type-flag'>{pageTypeFlag}</div>}
    {title && <h2 className={`lr-content-title lr-content-title-${titleSize}`}>{title}</h2>}
    {content &&
      content.map((item, i) => (
        <div
          className='lr-content-inner-content'
          key={`inner-content-${i}${
            item.subtitle && item.subtitle !== '' ? `-${item.subtitle}` : ''
          }`}
        >
          {item.subtitle && (
            <h3
              className='lr-content-subtitle'
              dangerouslySetInnerHTML={{ __html: item.subtitle }} // setting value via `dangerouslySetInnerHTML` to handle `&nbsp;` characters
            />
          )}
          {item.content && <div dangerouslySetInnerHTML={{ __html: item.content }} />}
        </div>
      ))}
    {cta && <DynamicCallToAction cta={cta} />}
  </div>
)

const LandingPageLRContent: React.FunctionComponent<Props> = ({
  pageTypeFlag,
  title,
  content,
  cta,
  textAlign,
  alt,
  imageUrl,
  imageBackgroundColor,
  style,
  titleSize,
  videoUrl,
  videoModalUrl,
  videoRatio,
}) => {
  const mediaComponent = buildMediaComponent(
    imageUrl,
    videoUrl,
    videoModalUrl,
    alt || title,
    imageBackgroundColor,
    videoRatio
  )
  const contentComponent = buildContentComponent(pageTypeFlag, title, content, cta, titleSize)

  const leftElement = textAlign === 'left' ? contentComponent : mediaComponent
  const rightElement = textAlign === 'right' ? contentComponent : mediaComponent

  return (
    <LandingPageSectionWrapper cssNamespace='lr-content-section' style={style}>
      <div
        className={`lr-content-grid-element grid-left content-${
          textAlign === 'left' ? 'text' : 'media'
        }`}
      >
        {leftElement}
      </div>
      <div
        className={`lr-content-grid-element grid-right content-${
          textAlign === 'left' ? 'media' : 'text'
        }`}
      >
        {rightElement}
      </div>
    </LandingPageSectionWrapper>
  )
}
LandingPageLRContent.defaultProps = {
  textAlign: 'right',
  titleSize: 'h2',
  videoRatio: 1.333,
} as Partial<Props>

export default LandingPageLRContent
