import React from "react";

interface Props {
  category: string;
  description?: string;
  icon: string;
  linkText: string;
  linkUrl: string;
  title: string;
}

const ResourceCard: React.FC<Props> = ({
  title,
  icon,
  category,
  description,
  linkText,
  linkUrl,
}) => {
  return (
    <div className="resource-card__container">
      <div className="resource-card__icon-wrapper">
        <i className={icon} />
        <h2 className="resource-card__category">{category}</h2>
      </div>
      <h3 className="resource-card__title">{title}</h3>
      <p className="resource-card__description">{description}</p>
      <div className="resource-card__link-wrapper">
        <a href={linkUrl} target="_blank">
          {linkText}
          <span className="resource-card__icon-link">
            <i className="fa-regular fa-arrow-right" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default ResourceCard;
