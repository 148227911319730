import React from 'react'
import { useRequestContext } from '../../../common/providers/RequestProvider'
import { LPMultiTileSection, MultiTile } from '../../api/Types'
import DynamicCallToAction from '../common/DynamicCallToAction'
import LandingPageSectionWrapper from '../common/LandingPageSectionWrapper'

type Props = LPMultiTileSection

const LandingPageMultiTile: React.FunctionComponent<Props> = ({
  style,
  title,
  subtitle,
  textAlign,
  titleTextAlign,
  countTiles,
  tiles,
  imageMaxWidth,
  imageBackgroundColor,
  cta,
}) => {
  const { isBot } = useRequestContext()

  const makeTile = (tile: MultiTile, idx: number): React.ReactNode => {
    const { title, titlePrefix, imageUrl, imageAlt, description, linkUrl, linkLabel } = tile

    const makeTileTitle = () => (
      <>
        {titlePrefix && <span className='multi-tile-loop-item-title-prefix'>{titlePrefix}</span>}{' '}
        <span className='multi-tile-loop-item-title-text'>{title}</span>
      </>
    )

    const makeTileImage = () => (
      <img
        className='multi-tile-loop-item-image'
        src={imageUrl}
        alt={imageAlt || title}
        loading='lazy'
      />
    )

    return (
      <div key={idx} className='multi-tile-loop-item'>
        {imageUrl && (
          <div
            className={`multi-tile-loop-item-image-wrapper ${
              imageBackgroundColor ? `bg-color-${imageBackgroundColor}` : ''
            }`}
            style={{ maxWidth: imageMaxWidth }}
          >
            {!isBot && linkUrl ? <a href={linkUrl}>{makeTileImage()}</a> : makeTileImage()}
          </div>
        )}
        {title && (
          <h3
            className='multi-tile-loop-item-title'
            style={{
              justifyContent: textAlign == 'center' ? 'center' : '',
            }}
          >
            {linkUrl ? <a href={linkUrl}>{makeTileTitle()}</a> : makeTileTitle()}
          </h3>
        )}
        {description && <p className='multi-tile-loop-item-desc'>{description}</p>}
        {linkUrl && linkLabel && (
          <a href={linkUrl} className='multi-tile-loop-item-link'>
            {linkLabel}
          </a>
        )}
      </div>
    )
  }

  const loopClassNames = ['multi-tile-loop']
  if (countTiles) {
    loopClassNames.push(`multi-tile-loop-${countTiles}`)
  }

  return (
    <LandingPageSectionWrapper cssNamespace='multi-tile-section' style={{ ...style, textAlign }}>
      {title && (
        <h2 className='multi-tile-section-title' style={{ textAlign: titleTextAlign }}>
          {title}
        </h2>
      )}
      {subtitle && (
        <p className='multi-tile-section-subtitle' style={{ textAlign: titleTextAlign }}>
          {subtitle}
        </p>
      )}
      {countTiles && tiles && (
        <div className='multi-tile-loop-wrapper'>
          <div className={loopClassNames.join(' ')}>{tiles.map(makeTile)}</div>
        </div>
      )}
      {cta && <DynamicCallToAction cta={cta} />}
    </LandingPageSectionWrapper>
  )
}

export default LandingPageMultiTile
