import React, { useState, useEffect } from "react";
import LandingPageSectionWrapper from "../common/LandingPageSectionWrapper";
import I18n from "../../../../helpers/I18n";

const LandingPageInteractiveSlider = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const sliderList = I18n.t(
    "views.landing_pages.hardware_landing_pages.machine_motion.interactive_slider.slider_list",
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % sliderList.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % sliderList.length);
  };

  const prevSlide = () => {
    setCurrentSlideIndex(
      (prevIndex) => (prevIndex - 1 + sliderList.length) % sliderList.length,
    );
  };
  return (
    <>
      <LandingPageSectionWrapper cssNamespace="interactive-slider-section">
        <div className="interactive-slider-section__container">
          <div className="interactive-slider-section__left-column">
            <h2 className="interactive-slider-section__header">
              {I18n.t(
                "views.landing_pages.hardware_landing_pages.machine_motion.interactive_slider.header",
              )}
            </h2>
            <p className="interactive-slider-section__description">
              {I18n.t(
                "views.landing_pages.hardware_landing_pages.machine_motion.interactive_slider.description",
              )}
            </p>
            <div>
              <div className="interactive-slider-section__controller-container">
                <button
                  className="interactive-slider-section__left-controller"
                  onClick={prevSlide}
                >
                  <i className="ti ti-square-rounded-chevron-left" />
                </button>
                <h3 className="interactive-slider-section__slider-title">
                  <span className="title_number">{String(currentSlideIndex + 1).padStart(2, "0")} </span>
                  {sliderList[currentSlideIndex].title}
                </h3>
                <button
                  className="interactive-slider-section__right-controller"
                  onClick={nextSlide}
                >
                  <i className="ti ti-square-rounded-chevron-right" />
                </button>
              </div>
              <p className="interactive-slider-section__slider-description">
                { sliderList[currentSlideIndex].description}{" "}
                <a href={ sliderList[currentSlideIndex].link_url } target="_blank">
                  Learn more
                </a>
              </p>
            </div>
          </div>
          <div className="interactive-slider-section__right-column">
            <img src={sliderList[currentSlideIndex].img_url} />
          </div>
        </div>
      </LandingPageSectionWrapper>
    </>
  );
};

export default LandingPageInteractiveSlider;
