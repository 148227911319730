import React from 'react'
import DocsContact from '../../docs/DocsContact'
import { LPStandardStartBuildingSection } from '../../api/Types'

type Props = LPStandardStartBuildingSection

const LandingPageStandardStartBuilding: React.FunctionComponent<Props> = ({
  title,
  description,
  buttons,
}) => {
  return <DocsContact description={description} title={title} buttons={buttons} />
}

export default LandingPageStandardStartBuilding
