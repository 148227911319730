import React from "react";
import LandingPageSectionWrapper from "../common/LandingPageSectionWrapper";
import { LandingPageEducationalResourcesSection } from "../../api/Types";
import ResourceCard from "../../../common/cards/ResourceCard";

type Props = LandingPageEducationalResourcesSection;

const LandingPageEducationalResources: React.FC<Props> = ({
  subtitle,
  title,
  description,
  cardDetails,
}) => {
  return (
    <>
      <LandingPageSectionWrapper cssNamespace="landing-page-educational-resources">
        <div className="landing-page-educational-resources__container">
          <h3 className="landing-page-educational-resources__subtitle">{subtitle}</h3>
          <h2 className="landing-page-educational-resources__title">{title}</h2>
          <p className="landing-page-educational-resources__description">{description}</p>
          <div className="landing-page-educational-resources__cards-container">
            {cardDetails.map((item) => (
              <ResourceCard
                icon={item.icon}
                category={item.category}
                description={item.description}
                linkText={item.link_text}
                linkUrl={item.link_url}
                title={item.title}
              />
            ))}</div>
        </div>
      </LandingPageSectionWrapper>
    </>
  );
};

export default LandingPageEducationalResources;
