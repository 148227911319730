import React from 'react'
import {
  LPHeroSection,
  CallToAction,
  CreateScopeOfWorkButton,
  ResourceDocObject,
  CallToActionColor,
} from '../../api/Types'
import DynamicCallToAction from '../common/DynamicCallToAction'
import EmailForm from '../../../../partials/common/EmailForm'
import LandingPageSectionWrapper from '../common/LandingPageSectionWrapper'
import { TechDocsUrlPrefixes } from '../../models/Constants'
import { useI18nContext } from '../../../common/providers/I18nProvider'
import classNames from 'classnames'
import { useRequestContext } from '../../../common/providers/RequestProvider'

type Props = LPHeroSection

const makeCaseStudyComponent = (
  uuid: string,
  resourcesByUuid: Record<string, ResourceDocObject>
) => {
  const caseStudy = resourcesByUuid[uuid]
  if (!caseStudy) return null

  return (
    <a
      href={`/${TechDocsUrlPrefixes.CaseStudy}/${caseStudy.url_slug}`}
      className='hero-section-case-study'
      data-analytic-id='hero-section-case-study-link'
    >
      <div className='hero-section-case-study-wrapper'>
        <div className='hero-section-case-inner'>
          <span className='hero-section-case-study-label'>Case Study</span>
          <h2 className='hero-section-case-study-title'>{caseStudy.title}</h2>
        </div>
      </div>
    </a>
  )
}

const makeEmailInputFormComponent = (mode: LPHeroSection['mode'] = undefined) => {
  let buttonStyle = 'button-outline'

  if (mode === 'dark') {
    buttonStyle = 'button-white-outline'
  }

  return (
    <div className='hero-section-email-form'>
      <EmailForm buttonSize='button-md' buttonStyle={buttonStyle} />
    </div>
  )
}

const makePartnerLogo = (partnerLogoURL, partnerLogoWidth) => (
  <img
    className='hero-section-partner-logo'
    src={partnerLogoURL}
    alt='partner logo'
    style={{ width: partnerLogoWidth }}
  />
)

const makeScopeOfWorkCTA = (
  scopeOfWorkCTA: CreateScopeOfWorkButton,
  mode: LPHeroSection['mode'] = undefined
) => {
  let buttonStyle = 'button-vention'

  if (mode === 'dark') {
    buttonStyle = 'button-white'
  }

  const classnames = classNames('button button-lg', buttonStyle)

  return (
    <div className='hero-section-cta'>
    </div>
  )
}

const LandingPageHero: React.FunctionComponent<Props> = ({
  pageTypeFlag,
  title,
  subtitle,
  caseStudy,
  partnerLogo,
  partnerLogoWidth,
  imageUrl,
  imageLayout,
  withEmailInputCTA,
  sectionCTA,
  sectionSecondaryCTA,
  scopeOfWorkCTA,
  style,
  resourcesByUuid,
  mode,
}) => {
  const { localizedPaths } = useI18nContext()
  const { isBot } = useRequestContext()

  const extraClasses = classNames({
    [`image-${imageLayout}`]: !!imageLayout,
    [`${mode}-mode`]: !!mode,
  })

  const makeSectionCTA = (sectionCTA: CallToAction, analyticIdPrefix: string) => {
    const sectionCTAProps = { ...sectionCTA }

    if (mode === 'dark') {
      let color: CallToActionColor = 'white'

      if (sectionCTA.color === 'outline') {
        color = 'outline-white'
      }

      sectionCTAProps.color = color
    }

    return (
      <div className='hero-section-cta'>
        <DynamicCallToAction
          analyticIdPrefix={analyticIdPrefix}
          cta={sectionCTAProps}
          localizedPaths={localizedPaths}
        />
      </div>
    )
  }

  return (
    <LandingPageSectionWrapper
      cssNamespace='hero-section'
      extraClasses={extraClasses}
      style={style}
    >
      <div className='hero-section-text'>
        {pageTypeFlag && <div className='hero-section-page-type-flag'>{pageTypeFlag}</div>}
        {title && <h1 className='hero-section-title'>{title}</h1>}
        {subtitle && (
          <div className='hero-section-subtitle' dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
        {caseStudy?.uuid && makeCaseStudyComponent(caseStudy.uuid, resourcesByUuid)}
        {withEmailInputCTA && makeEmailInputFormComponent(mode)}
        {!isBot && partnerLogo && makePartnerLogo(partnerLogo, partnerLogoWidth)}
        <div className='hero-section-cta-wrapper'>
          {sectionCTA && makeSectionCTA(sectionCTA, 'hero-section-cta')}
          {sectionSecondaryCTA && makeSectionCTA(sectionSecondaryCTA, 'hero-section-secondary-cta')}
          {scopeOfWorkCTA && makeScopeOfWorkCTA(scopeOfWorkCTA, mode)}
        </div>
      </div>
      {!isBot && imageLayout === 'half' && imageUrl && (
        <div
          className='hero-section-image'
          title={pageTypeFlag}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
    </LandingPageSectionWrapper>
  )
}

LandingPageHero.defaultProps = {
  imageLayout: 'half',
  partnerLogoWidth: 170,
} as Partial<Props>

export default LandingPageHero
