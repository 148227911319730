import React from 'react'
import Loading from './Loading'

interface Props {
  readonly searchFunction: (text: string) => void
  readonly autoSearch?: boolean
  readonly placeholder?: string
  readonly searchTerm?: string
  readonly autoFocus?: boolean
  readonly inputId?: string
  readonly clearSearch?: boolean
  readonly dataTestId?: string
  readonly isLoading?: boolean
}
interface State {
  readonly searchTerm: string
  readonly lastSearch: string
  readonly timerHandle?: number
}

const searchDelay = 500

class Search extends React.Component<Props, State> {
  timerHandle?: number // Timer for autosearch
  constructor(props) {
    super(props)
    const { searchTerm } = this.props
    const currentSearchTerm = searchTerm || ''
    this.state = {
      searchTerm: currentSearchTerm,
      lastSearch: currentSearchTerm,
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.updateSearchValue = this.updateSearchValue.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    nextProps.searchTerm &&
      this.setState({
        searchTerm: nextProps.searchTerm,
      })
  }

  handleSearch(event) {
    const { lastSearch } = this.state
    const { autoSearch } = this.props
    const searchedValue = event.target.value
    const recentAutoSearch = searchedValue === lastSearch && autoSearch
    if (event.key === 'Enter' && !recentAutoSearch) {
      this.props.searchFunction(searchedValue)
    }
  }

  handleClearSearch() {
    const { searchFunction } = this.props
    searchFunction('')
    this.setState({ searchTerm: '' })
  }

  updateSearchValue(event) {
    const { autoSearch, searchFunction } = this.props
    const { timerHandle } = this.state
    const searchTerm = event.target.value
    this.setState({ searchTerm, lastSearch: searchTerm })

    if (autoSearch) {
      timerHandle !== undefined && window.clearTimeout(timerHandle)
      const nextHandle = window.setTimeout(() => searchFunction(searchTerm), searchDelay)
      this.setState({ timerHandle: nextHandle })
    }
  }

  render() {
    const {
      children,
      placeholder,
      searchFunction,
      autoFocus = false,
      inputId,
      clearSearch,
      dataTestId,
      isLoading,
    } = this.props
    const { searchTerm } = this.state
    return (
      <div className='search' data-testid={dataTestId}>
        {children}
        <div className='wrapper'>
          <input
            autoFocus={autoFocus}
            className='form-control'
            type='text'
            onKeyDown={this.handleSearch}
            onChange={this.updateSearchValue}
            value={searchTerm}
            placeholder={placeholder}
            id={inputId}
            disabled={isLoading}
          />
          <button
            className='button button-blank button-search'
            onClick={() => searchFunction(searchTerm)}
            disabled={isLoading}
          >
            {isLoading ? <Loading /> : <i className='far fa-search'></i>}
          </button>
          {clearSearch && searchTerm != '' && (
            <button
              className='button button-blank button-clear'
              onClick={() => this.handleClearSearch()}
            >
              <i className='fal fa-times'></i>
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default Search
